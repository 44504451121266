import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopicSelect from "../components/topic-select.js"
import { FaCaretDown } from "react-icons/fa"
export default ({ data, location }) => {
  const { title: siteTitle } = data.site.siteMetadata
  const posts = data.allMarkdownRemark.edges
  const [selection, setSelection] = useState(
    location.state && location.state.topic
      ? [
          {
            value: location.state.topic,
            label:
              location.state.topic.charAt(0).toUpperCase() +
              location.state.topic.slice(1),
          },
        ]
      : []
  )
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const archivedPosts = posts
    .filter(
      ({ node }) =>
        !selection ||
        selection.length === 0 ||
        selection.some(({ value }) => node.frontmatter.tags.includes(value))
    )
    .map(({ node }) => {
      const { fields, frontmatter } = node
      const { date, title, tags } = frontmatter
      const { slug } = fields
      const dateObj = new Date(date)
      const month = dateObj.getMonth() + 1
      const day = dateObj.getDate()
      const year = dateObj.getFullYear()
      return { month, day, year, slug, title, tags }
    })
    .reduce((acc, next) => {
      let added = false
      let newAcc = acc.map(i => {
        const { month, year } = i
        if (month === next.month && year === next.year) {
          added = true
          return { ...i, posts: [...i.posts, next] }
        }
        return i
      })
      if (!added) {
        return [...acc, { month: next.month, year: next.year, posts: [next] }]
      }
      return newAcc
    }, [])
    .map(a => ({ ...a, postCount: a.posts.length }))
  const onSelect = selects => {
    setSelection(selects)
  }
  const firstXDefaultOpen = 2 // two of the first archived section will be open when the page loads
  let open = 0
  const [archiveSectionOpenStatus, setArchiveOpenStatus] = useState(
    archivedPosts.reduce(
      (acc, { month, year }) => ({
        ...acc,
        [`${month}${year}`]: open++ < firstXDefaultOpen ? true : false,
      }),
      {}
    )
  )

  const setOpen = date => {
    setArchiveOpenStatus({
      ...archiveSectionOpenStatus,
      [date]: !archiveSectionOpenStatus[date],
    })
  }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Archive" />
      <TopicSelect onSelect={onSelect} value={selection} />
      <h4>
        {archivedPosts.reduce((acc, next) => acc + next.postCount, 0)} posts
        found
      </h4>
      <div className="post-archive">
        {archivedPosts.map(({ posts, month, year }) => (
          <div key={`${month}${year}`}>
            <p onClick={() => setOpen(`${month}${year}`)}>
              <span className="emoji">📅</span> {monthNames[month - 1]} {year}{" "}
              <FaCaretDown />
            </p>
            {archiveSectionOpenStatus[`${month}${year}`] ? (
              <div className="flex-column-start archived-posts">
                {posts.map(p => (
                  <Link key={p.title} to={p.slug}>
                    {p.month}/{p.day}/{p.year} - {p.title}
                  </Link>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        popularTopics
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            cover_image {
              base
            }
            author
          }
        }
      }
    }
  }
`
