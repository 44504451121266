import React from "react"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { graphql, useStaticQuery } from "gatsby"

const animatedComponents = makeAnimated()

export default ({ onSelect, value }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          topics {
            value
            label
          }
        }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              tags
              cover_image {
                base
              }
              author
            }
          }
        }
      }
    }
  `)
  const { topics } = data.site.siteMetadata
  const posts = data.allMarkdownRemark.edges
  const topicCounts = posts.reduce(
    (acc, { node }) => {
      node.frontmatter.tags.forEach(t => {
        acc[t] = acc[t] + 1
      })
      return acc
    },
    topics.reduce((acc, { value }) => ({ ...acc, [value]: 0 }), {})
  )
  const sortedTopics = topics
    .sort(({ value: t1 }, { value: t2 }) => (t1 < t2 ? -1 : 1))
    .map(t => {
      return { ...t, label: `${t.label} (${topicCounts[t.value]})` }
    })

  return (
    <div>
      <h4>💡 All Topics</h4>
      <Select
        className="topic-select"
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        value={value}
        options={sortedTopics}
        onChange={onSelect}
        placeholder="select your topic..."
      />
    </div>
  )
}
